import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import {routes} from "./routes";

import configureStore from "./store";

import "./css/index.css";

import * as serviceWorker from "./serviceWorker";
import {language, messages} from "./utils/localeUtility";
import HotSwappingIntlProvider from "./components/common/HotSwappingIntlProvider";

export const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        {/*<HotSwappingIntlProvider*/}
        {/*    initialLocale={language}*/}
        {/*    initialMessages={messages}>*/}
            <BrowserRouter children={routes}/>
        {/*</HotSwappingIntlProvider>*/}
    </Provider>,
    document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
