import React, {Suspense, lazy} from "react";
import {Route, Switch} from "react-router-dom";

const App = (lazy(() => (import("./App"))));

export const routes = <div style={{height: "100%"}}>
    <Suspense fallback={<div>Loading...</div>}>
        <Switch>
            <Route path="/" component={App}/>
        </Switch>
    </Suspense>
</div>;
